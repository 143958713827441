import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import classNames from "classnames"

import "./header.scss"

import SidebarContext from "../context/SidebarContext"

const Header = ({ siteTitle = ``}) => (
  <SidebarContext.Consumer>
    {({ sidebarVisible }) => (
      <header
        className={classNames("header-wrapper", {
          "header-wrapper--sidebar": sidebarVisible,
        })}
      >
        <div className="header">
          <h1 className="header-text">
            <Link
              to="/"
              style={{
                color: `white`,
                textDecoration: `none`,
              }}
            >
              {siteTitle}
            </Link>
          </h1>
        </div>
      </header>
    )}
  </SidebarContext.Consumer>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

export default Header
