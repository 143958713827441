import React from "react"

const SidebarContext = React.createContext()

class SidebarProvider extends React.Component {
  state = {
    sidebarVisible: false,
  }

  showSidebar = () => {
    this.setState({ sidebarVisible: true })
  }

  hideSidebar = () => {
    this.setState({ sidebarVisible: false })
  }

  render() {
    const { children } = this.props
    const { sidebarVisible } = this.state
    return (
      <SidebarContext.Provider
        value={{
          sidebarVisible,
          showSidebar: this.showSidebar,
          hideSidebar: this.hideSidebar,
        }}
      >
        {children}
      </SidebarContext.Provider>
    )
  }
}

export default SidebarContext
export { SidebarProvider }
