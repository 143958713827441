import React from "react"
import { Link } from "gatsby-link"
import classNames from "classnames"

import "./sidebar.scss"

import Avatar from "./avatar"
import SidebarContext from "../context/SidebarContext"

const NavLink = ({ location, label, currentPath, hideSidebar }) => (
  <Link to={location} onClick={hideSidebar}>
    <div
      className={classNames("sidebar-nav-link", {
        "sidebar-nav-link--current": location === currentPath,
      })}
    >
      {label}
    </div>
  </Link>
)

const Sidebar = ({ location }) => (
  <SidebarContext.Consumer>
    {({ sidebarVisible, hideSidebar, showSidebar }) => (
      <>
        <button
          className={classNames("sidebar-toggle", {
            // sidebar visible then this button should be hidden
            "sidebar-toggle--hidden": sidebarVisible,
          })}
          onClick={showSidebar}
        >
          <div className="sidebar-toggle-icon">
            <div className="sidebar-toggle-icon-part" />
            <div className="sidebar-toggle-icon-part sidebar-toggle-icon-part--middle" />
            <div className="sidebar-toggle-icon-part" />
          </div>
        </button>
        <section
          className={classNames("sidebar", {
            "sidebar--hidden": !sidebarVisible,
          })}
        >
          <header className="sidebar-header">
            <div className="sidebar-avatar">
              <Avatar />
            </div>
            <h3 className="sidebar-name">Vlad Timofeev</h3>
            <p className="sidebar-title">
              <i>Software Engineer</i>
            </p>
          </header>
          <nav className="sidebar-nav">
            <NavLink
              location="/"
              label="Home"
              currentPath={location.pathname}
              hideSidebar={hideSidebar}
            />
            <NavLink
              location="/portfolio"
              label="Portfolio"
              currentPath={location.pathname}
              hideSidebar={hideSidebar}
            />
            <NavLink
              location="/references"
              label="References"
              currentPath={location.pathname}
              hideSidebar={hideSidebar}
            />
            <NavLink
              location="/contact"
              label="Contact"
              currentPath={location.pathname}
              hideSidebar={hideSidebar}
            />
          </nav>
        </section>
      </>
    )}
  </SidebarContext.Consumer>
)

export default Sidebar
