/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import classNames from "classnames"

import "./layout.scss"

import Header from "./header"
import Sidebar from "./sidebar"
import SidebarContext from "../context/SidebarContext"
import GithubImage from "../images/github.svg"
import LinkedInImage from "../images/linkedin.svg"
import Constants from "../constants"

const SocialLink = ({ url, src, alt }) => (
  <a
    href={url}
    target="_blank"
    rel="noopener noreferrer"
    className="footer-social-link"
  >
    <img alt={alt} src={src} className="footer-social-link-image" />
  </a>
)

const SocialLinks = () => (
  <div className="footer-social-links">
    <SocialLink
      url={Constants.MY_GITHUB_URL}
      alt="Github"
      src={GithubImage}
    ></SocialLink>
    <SocialLink
      url={Constants.MY_LINKEDIN_URL}
      alt="LinkedIn"
      src={LinkedInImage}
    ></SocialLink>
  </div>
)

const Overlay = ({ sidebarVisible, hideSidebar }) => (
  <div
    onTouchStart={hideSidebar}
    onMouseDown={hideSidebar}
    className={classNames("background-overlay", {
      "background-overlay--active": sidebarVisible,
    })}
  />
)

const Layout = ({ children, location }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <SidebarContext.Consumer>
      {({ sidebarVisible, hideSidebar }) => (
        <>
          <Overlay sidebarVisible={sidebarVisible} hideSidebar={hideSidebar} />
          <Header siteTitle={data.site.siteMetadata.title} />
          <Sidebar location={location} />
          <div
            className={classNames("outer-wrapper", {
              "outer-wrapper--sidebar": sidebarVisible,
            })}
          >
            <div className="wrapper">
              <main>{children}</main>
            </div>
            <footer className="main-footer">
              <div className="wrapper">
                <div className="footer-wrapper">
                  <div className="main-footer-text">
                    <small>© {new Date().getFullYear()} Vlad Timofeev</small>
                  </div>
                  <SocialLinks />
                </div>
              </div>
            </footer>
          </div>
        </>
      )}
    </SidebarContext.Consumer>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
