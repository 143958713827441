import React from "react"

import { SidebarProvider } from "./src/context/SidebarContext"
import Layout from "./src/components/layout"

export const wrapRootElement = ({ element }) => (
  <SidebarProvider>{element}</SidebarProvider>
)

export const wrapPageElement = ({ element, props }) => {
  return <Layout {...props}>{element}</Layout>
}
